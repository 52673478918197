/* eslint-disable import/max-dependencies */
import React from "react";
import { AccessDenied } from "@fas/ui-core";
import { Navigate } from "react-router-dom";
import type { Feature } from "./modules/Affiliates/types";
import PrivatRoute from "./components/App/PrivateRoute";
import RouterSlots from "./modules/components/RouterSlots";
import toOperation from "./toOperation";

const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const CpaOfferCampaigns = React.lazy(() => import("./pages/CpaOfferCampaigns"));
const CpaOffer = React.lazy(() => import("./pages/CpaOfferIndex"));
const CpaOfferForm = React.lazy(() => import("./pages/CpaOffer"));
const ManageAffiliate = React.lazy(() => import("./pages/ManageAffiliate"));
const CreateAffiliate = React.lazy(() => import("./pages/CreateAffiliate"));
const Affiliates = React.lazy(() => import("./modules/Affiliates"));
const TopOffers = React.lazy(() => import("./pages/TopOffers"));
const TopOffersForm = React.lazy(() => import("./pages/TopOffersForm/TopOffersForm"));
const PaymentInfo = React.lazy(() => import("./pages/PaymentInfo"));
const Subrates = React.lazy(() => import("./pages/Subrates"));
const SubratesIndex = React.lazy(() => import("./containers/SubratesIndex"));
// eslint-disable-next-line import/default
const SubrateForm = React.lazy(() => import("./containers/SubrateForm"));
const SubrateInfo = React.lazy(() => import("./containers/SubrateInfo"));
const PayoutTermsCreate = React.lazy(() => import("./pages/PayoutTermsCreate"));
const PayoutTerms = React.lazy(() => import("./pages/PayoutTerms/PayoutTerms"));
const SmuDepartments = React.lazy(() => import("./pages/SmuDepartments"));
const SmuDepartmentsForm = React.lazy(() => import("./pages/SmuDepartmentsForm"));
const SmuBrandList = React.lazy(() => import("./pages/SmuBrandList"));
const SmuBrandForm = React.lazy(() => import("./pages/SmuBrandForm"));
const ProductCompany = React.lazy(() => import("./pages/ProductCompany/ProductCompany"));
const ProductCompanyForm = React.lazy(() => import("./pages/ProductCompanyForm/ProductCompanyForm"));
const PotentialPartner = React.lazy(() => import("./pages/PotentialPartner/PotentialPartner"));
const PotentialPartnerForm = React.lazy(() => import("./pages/PotentialPartnerForm/PotentialPartnerForm"));
const AdvertiserInvoicesPage = React.lazy(() => import("./pages/AdvertiserInvoices"));
const AdvertiserInvoicesFormPage = React.lazy(() => import("./pages/AdvertiserInvoicesForm/AdvertiserInvoicesForm"));
const UtmCampaignDictionary = React.lazy(() => import("./pages/UtmCampaignDictionary"));
const CrmMails = React.lazy(() => import("./pages/CrmMails"));
const CrmMailsList = React.lazy(() => import("./containers/CrmMailsList"));
// eslint-disable-next-line import/default
const CrmMailsForm = React.lazy(() => import("./containers/CrmMailsForm"));
const CrmTemplates = React.lazy(() => import("./pages/CrmTemplates"));
const CrmTemplatesList = React.lazy(() => import("./containers/CrmTemplatesList"));
// eslint-disable-next-line import/default
const CrmTemplatesForm = React.lazy(() => import("./containers/CrmTemplatesForm"));

function RedirectPath({ fromStart, to }: { fromStart: string, to: string }) { // todo remove after backend fix routes
  // eslint-disable-next-line no-console
  console.warn(`Redirecting from ${window.location.pathname} to ${window.location.pathname.replace(fromStart, to) || "/"}`);
  return <Navigate replace to={(window.location.pathname.replace(fromStart, to) + window.location.search) || "/"} />;
}

const slots: Feature[] = [
  {
    Component: Affiliates,
    operationIds: [],
    url: "*",
    label: "Affiliates",
  },
  {
    Component: Dashboard,
    operationIds: toOperation([{ path: "/api/v1/cpa/dashboard", method: "GET" }, "accessManageAffiliate"]),
    url: "dashboard",
    label: "Dashboard",
  },
  ...[
    "affiliates",
    "affiliates/offers/*",
    "affiliates/offer/*",
    "affiliates/tools/*",
    "affiliates/management/*",
    "affiliates/advertiser/*",
    "affiliates/adNetwork/*",
  ].map((url) => ({
    Component: () => <RedirectPath fromStart="/affiliates" to="" />,
    operationIds: [],
    url,
    label: "redirect",
  })),
  {
    Component: PaymentInfo,
    operationIds: toOperation([{ path: "/api/v1/cpa/affiliate", method: "POST" }]),
    url: "payments/paymentInfo",
    label: "payments",
  },
  {
    Component: PayoutTermsCreate,
    operationIds: toOperation([{ path: "/api/v1/payoutTerms", method: "POST" }]),
    url: "payoutTerms/create",
    label: "payoutTerms",
  },
  {
    Component: PayoutTermsCreate,
    operationIds: toOperation([{ path: "/api/v1/payoutTerms", method: "POST" }]),
    url: "payoutTerms/:id",
    label: "payoutTerms",
  },
  {
    Component: PayoutTerms,
    operationIds: toOperation([{ path: "/api/v1/payoutTerms/filter", method: "POST" }]),
    url: "payoutTerms",
    label: "payoutTerms",
  },
  {
    Component: SmuDepartments,
    operationIds: toOperation([{ path: "/api/v1/bank/departments", method: "GET" }]),
    url: "smuDepartments",
    label: "smuDepartments",
  },
  {
    Component: SmuDepartmentsForm,
    operationIds: toOperation([{ path: "/api/v1/bank/departments", method: "GET" }]),
    url: "smuDepartments/:id",
    label: "smuDepartments",
  },
  {
    Component: SmuBrandList,
    operationIds: toOperation([{ path: "/api/v1/bank/brands", method: "GET" }]),
    url: "smuBrands",
    label: "smuBrands",
  },
  {
    Component: SmuBrandForm,
    operationIds: toOperation([{ path: "/api/v1/bank/brands", method: "GET" }]),
    url: "smuBrands/create",
    label: "smuBrands",
  },
  {
    Component: SmuBrandForm,
    operationIds: toOperation([{ path: "/api/v1/bank/brands", method: "GET" }]),
    url: "smuBrands/:id",
    label: "smuBrands",
  },
  {
    Component: ProductCompany,
    operationIds: toOperation([{ path: "/api/v1/bank/companies", method: "GET" }]),
    url: "smuProductCompany",
    label: "smuProductCompany",
  },
  {
    Component: ProductCompanyForm,
    operationIds: toOperation([{ path: "/api/v1/bank/companies", method: "GET" }]),
    url: "smuProductCompany/create",
    label: "smuProductCompany",
  },
  {
    Component: ProductCompanyForm,
    operationIds: toOperation([{ path: "/api/v1/bank/companies", method: "GET" }]),
    url: "smuProductCompany/:id",
    label: "smuProductCompany",
  },
  ...[
    "sumProductCompany",
    "sumProductCompany/*",
  ].map((url) => ({
    Component: () => <RedirectPath fromStart="/sumProductCompany" to="/smuProductCompany" />,
    operationIds: [],
    url,
    label: "redirect",
  })),
  {
    Component: UtmCampaignDictionary,
    operationIds: toOperation([{ path: "/api/v1/generatedUtmCampaign", method: "GET" }]),
    url: "redefinedUtmCampaign",
    label: "redefinedUtmCampaign",
  },
  {
    Component: () => (
      <RouterSlots
        Layout={CrmMails}
        Index={<CrmMailsList />}
        slots={[
          {
            Component: CrmMailsForm,
            operationIds: toOperation([{ path: "/api/v1/crm/mail", method: "POST" }]),
            url: "create",
            label: "crm",
          },
          {
            Component: CrmMailsForm,
            operationIds: toOperation([{ path: "/api/v1/crm/mail", method: "GET" }]),
            url: ":id",
            label: "crm",
          },
        ]}
      />
    ),
    operationIds: toOperation([{ path: "/api/v1/crm/mail/filter", method: "POST" }]),
    url: "crm/mails/*",
    label: "crm",
  },
  {
    Component: () => (
      <RouterSlots
        Layout={CrmTemplates}
        Index={<CrmTemplatesList />}
        slots={[
          {
            Component: CrmTemplatesForm,
            operationIds: toOperation([{ path: "/api/v1/crm/mailTemplate", method: "POST" }]),
            url: "create",
            label: "crm",
          },
          {
            Component: CrmTemplatesForm,
            operationIds: toOperation([{ path: "/api/v1/crm/mailTemplate", method: "GET" }]),
            url: ":id",
            label: "crm",
          },
        ]}
      />
    ),
    operationIds: toOperation([{ path: "/api/v1/template/mailTemplate/filter", method: "POST" }]),
    url: "crm/templates/*",
    label: "crm",
  },
  {
    Component: CpaOfferCampaigns,
    operationIds: toOperation([{ path: "/api/v1/template/cpaOffersCampaign/filter", method: "POST" }, "accessManageAffiliate"]),
    url: "cpaOffers/campaigns",
    label: "CpaOfferCampaigns",
  },
  {
    Component: CpaOffer,
    operationIds: toOperation([{ path: "/api/v1/offer/list", method: "GET" }, "accessManageAffiliate"]),
    url: "cpaOffers",
    label: "CpaOffer",
  },
  {
    Component: CpaOfferForm,
    operationIds: toOperation([{ path: "/api/v1/cpa/offer", method: "POST" }, "accessManageAffiliate"]),
    url: "cpaOffers/add",
    label: "CpaOfferForm",
  },
  {
    Component: CpaOfferForm,
    operationIds: toOperation([{ path: "/api/v1/cpa/offer", method: "POST" }, "accessManageAffiliate"]),
    url: "cpaOffers/:id",
    label: "CpaOfferForm",
  },
  {
    Component: CpaOfferForm,
    operationIds: toOperation([{ path: "/api/v1/cpa/offer", method: "POST" }, "accessManageAffiliate"]),
    url: "cpaOffers/:id/clone",
    label: "CpaOfferForm",
  },
  {
    Component: ManageAffiliate,
    operationIds: toOperation([{ path: "/api/v1/cpa/affiliate", method: "GET" }, "accessManageAffiliate"]),
    url: "manageAffiliate",
    label: "manageAffiliate",
  },
  {
    Component: () => (
      <PrivatRoute
        permissions={[]}
        component={(props) => <CreateAffiliate {...props} mode="add" />}
      />
    ),
    operationIds: toOperation([{ path: "/api/v1/cpa/affiliate", method: "POST" }, "accessManageAffiliate"]),
    url: "manageAffiliate/create",
    label: "manageAffiliate",
  },
  {
    Component: () => (
      <PrivatRoute
        permissions={[]}
        component={(props) => <CreateAffiliate {...props} mode="edit" />}
      />
    ),
    operationIds: toOperation([{ path: "/api/v1/cpa/affiliate", method: "POST" }, "accessManageAffiliate"]),
    url: "manageAffiliate/edit/:id",
    label: "manageAffiliate",
  },
  {
    Component: () => (
      <PrivatRoute
        permissions={[]}
        component={(props) => <CreateAffiliate {...props} mode="edit" />}
      />
    ),
    operationIds: toOperation([{ path: "/api/v1/cpa/affiliate", method: "POST" }, "accessManageAffiliate"]),
    url: "manageAffiliate/edit/:id/:tab",
    label: "manageAffiliate",
  },
  {
    Component: TopOffers,
    operationIds: toOperation([{ path: "/api/v1/topOffers/filters", method: "POST" }]),
    url: "topOffers",
    label: "topOffers",
  },
  {
    Component: TopOffersForm,
    operationIds: toOperation([{ path: "/api/v1/topOffers", method: "POST" }]),
    url: "topOffers/create",
    label: "topOffers",
  },
  {
    Component: TopOffersForm,
    operationIds: toOperation([{ path: "/api/v1/topOffers", method: "POST" }]),
    url: "topOffers/:id",
    label: "topOffers",
  },
  {
    Component: () => (
      <RouterSlots
        Layout={Subrates}
        Index={<SubratesIndex />}
        slots={[
          {
            Component: SubrateForm,
            operationIds: [],
            url: "create",
            label: "subrates",
          },
          {
            Component: SubrateForm,
            operationIds: [],
            url: "edit/:id",
            label: "subrates",
          },
          {
            Component: SubrateForm,
            operationIds: [],
            url: "clone/:id",
            label: "subrates",
          },
          {
            Component: SubrateInfo,
            operationIds: [],
            url: "info",
            label: "subrates",
          },
        ]}
      />
    ),
    operationIds: toOperation([{ path: "/api/v1/dictionary/subrate", method: "POST" }]),
    url: "subrates/*",
    label: "subrates",
  },
  {
    Component: PotentialPartner,
    operationIds: toOperation([{ path: "/api/v1/potentialPartner", method: "GET" }]),
    url: "potentialPartner",
    label: "potentialPartner",
  },
  {
    Component: PotentialPartnerForm,
    operationIds: toOperation([{ path: "/api/v1/potentialPartner", method: "GET" }]),
    url: "potentialPartner/create",
    label: "potentialPartner",
  },
  {
    Component: PotentialPartnerForm,
    operationIds: toOperation([{ path: "/api/v1/potentialPartner", method: "GET" }]),
    url: "potentialPartner/:id",
    label: "potentialPartner",
  },
  {
    Component: AdvertiserInvoicesPage,
    operationIds: toOperation([{ path: "/api/v1/cpa/advertiser/invoices", method: "POST" }]),
    url: "advertiserInvoices",
    label: "advertiserInvoices",
  },
  {
    Component: AdvertiserInvoicesFormPage,
    operationIds: toOperation([{ path: "/api/v1/cpa/advertiser/invoices", method: "POST" }]),
    url: "advertiserInvoices/create",
    label: "advertiserInvoices",
  },
  {
    Component: AdvertiserInvoicesFormPage,
    operationIds: toOperation([{ path: "/api/v1/cpa/advertiser/invoices", method: "POST" }]),
    url: "advertiserInvoices/:id",
    label: "advertiserInvoices",
  },
  {
    Component: AccessDenied,
    operationIds: [],
    url: "accessDenied",
    label: "accessDenied",
  },
];

export default slots;
